"use client";
import { ThemeProvider } from "next-themes";
import { ReactNode } from "react";
import { SessionProvider } from "next-auth/react";
import StoreProvider from "@/store/provider";
import { NextUIProvider } from "@nextui-org/react";

function Providers({ children }: { children: ReactNode }) {
  return (
    <SessionProvider>
      <ThemeProvider defaultTheme="dark" attribute="class">
        <StoreProvider>
          <NextUIProvider>
            <main className="dark text-foreground bg-background">{children}</main>
          </NextUIProvider>
        </StoreProvider>
      </ThemeProvider>
    </SessionProvider>
  );
}

export default Providers;
